import React from "react";
import Izvjestaj2020 from "../../pdf/2020.pdf";
import Izvjestaj12020 from "../../pdf/I-2020.pdf";
import Izvjestaj22020 from "../../pdf/II-2020.pdf";
import Izvjestaj32020 from "../../pdf/III-2020.pdf";
import Izvjestaj2019 from "../../pdf/2019.pdf";
import Izvjestaj12019 from "../../pdf/I-2019.pdf";
import Izvjestaj22019 from "../../pdf/II-2019.pdf";
import Izvjestaj32019 from "../../pdf/III-2019.pdf";
import Izvjestaj2018 from "../../pdf/2018.pdf";
import Izvjestaj12018 from "../../pdf/I-2018.pdf";
import Izvjestaj22018 from "../../pdf/II-2018.pdf";
import Izvjestaj32018 from "../../pdf/III-2018.pdf";
import Izvjestaj2017 from "../../pdf/2017.pdf";
import Izvjestaj12017 from "../../pdf/I-2017.pdf";
import Izvjestaj22017 from "../../pdf/II-2017.pdf";
import Izvjestaj32017 from "../../pdf/III-2017.pdf";
import Izvjestaj12021 from "../../pdf/I-2021.pdf";
import Izvjestaj22021 from "../../pdf/II2021.pdf";
import Izvjestaj32021 from "../../pdf/III-2021.pdf";
import Izvjestaj42021 from "../../pdf/IV-2021.pdf";
import Izvjestaj12022 from "../../pdf/I-2022.pdf";
import Izvjestaj22022 from "../../pdf/II-2022.pdf";
import Izvjestaj32022 from "../../pdf/III-2022.pdf";
import Izvjestaj2022 from "../../pdf/2022.pdf";
import Izvjestaj12023 from "../../pdf/I-2023.pdf";
import Izvjestaj22023 from "../../pdf/II-2023.pdf";
import Izvjestaj32023 from "../../pdf/III-2023.pdf";
import Izvjestaj42023 from "../../pdf/IV-2023.pdf";
import Izvjestaj2023 from "../../pdf/2023.pdf";
import Izvjestaj12024 from "../../pdf/I-2024.pdf";
import Izvjestaj22024 from "../../pdf/II - 2024.pdf";

import styles from "../Styles/Izvjestaji.module.css";

const Izvjestaji = (props) => {
  let izvjestaj = `${props.language ? "Izvjestaj" : "Report"}`;

  return (
    <div className={styles.izvjestaji}>
      <a href={Izvjestaj22024} target="_blank">
        {izvjestaj} II 2024
      </a>
      <a href={Izvjestaj12024} target="_blank">
        {izvjestaj} I 2024
      </a>
      <hr />
      <a href={Izvjestaj2023} target="_blank">
        {izvjestaj} 2023
      </a>
      <a href={Izvjestaj42023} target="_blank">
        {izvjestaj} IV 2023
      </a>
      <a href={Izvjestaj32023} target="_blank">
        {izvjestaj} III 2023
      </a>
      <a href={Izvjestaj22023} target="_blank">
        {izvjestaj} II 2023
      </a>
      <a href={Izvjestaj12023} target="_blank">
        {izvjestaj} I 2023
      </a>
      <hr />
      <a href={Izvjestaj2022} target="_blank">
        {izvjestaj} 2022
      </a>
      <a href={Izvjestaj12022} target="_blank">
        {izvjestaj} I 2022
      </a>
      <a href={Izvjestaj22022} target="_blank">
        {izvjestaj} II 2022
      </a>
      <a href={Izvjestaj32022} target="_blank">
        {izvjestaj} III 2022
      </a>
      <hr />
      <a href={Izvjestaj42021} target="_blank">
        {izvjestaj} 2021
      </a>
      <a href={Izvjestaj12021} target="_blank">
        {izvjestaj} I 2021
      </a>
      <a href={Izvjestaj22021} target="_blank">
        {izvjestaj} II 2021
      </a>
      <a href={Izvjestaj32021} target="_blank">
        {izvjestaj} III 2021
      </a>
      <hr />
      <a href={Izvjestaj2020} target="_blank">
        {izvjestaj} 2020
      </a>
      <a href={Izvjestaj12020} target="_blank">
        {izvjestaj} I 2020
      </a>
      <a href={Izvjestaj22020} target="_blank">
        {izvjestaj} II 2020
      </a>
      <a href={Izvjestaj32020} target="_blank">
        {izvjestaj} III 2020
      </a>
      <hr />
      <a href={Izvjestaj2019} target="_blank">
        {izvjestaj} 2019
      </a>
      <a href={Izvjestaj12019} target="_blank">
        {izvjestaj} I 2019
      </a>
      <a href={Izvjestaj22019} target="_blank">
        {izvjestaj} II 2019
      </a>
      <a href={Izvjestaj32019} target="_blank">
        {izvjestaj} III 2019
      </a>
      <hr />
      <a href={Izvjestaj2018} target="_blank">
        {izvjestaj} 2018
      </a>
      <a href={Izvjestaj12018} target="_blank">
        {izvjestaj} I 2018
      </a>
      <a href={Izvjestaj22018} target="_blank">
        {izvjestaj} II 2018
      </a>
      <a href={Izvjestaj32018} target="_blank">
        {izvjestaj} III 2018
      </a>
      <hr />
      <a href={Izvjestaj2017} target="_blank">
        {izvjestaj} 2017
      </a>
      <a href={Izvjestaj12017} target="_blank">
        {izvjestaj} I 2017
      </a>
      <a href={Izvjestaj22017} target="_blank">
        {izvjestaj} II 2017
      </a>
      <a href={Izvjestaj32017} target="_blank">
        {izvjestaj} III 2017
      </a>
    </div>
  );
};

export default Izvjestaji;
